import "../scss/main.scss";
import 'slick-carousel';

$(function(){

    $('body').addClass('ready');

    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar = 0; }

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('.bt-search').on('click', function(){
        $('#search').toggleClass('opensearch');
    });
    $('.search--close').on('click', function(){
        $('#search').removeClass('opensearch');
    });

    // INIT ALL FUNCTIONS
    resize();
    if( $('.backImg').length != 0) {imgBack(); }
    if( $('.bloc--carrousel').length != 0 ||
    $('.intro--galerie').length != 0 || 
    $('.bloc--slider-cnt').length != 0 || 
    $('.bloc--sliderChiffres').length != 0 ||
    $('.devis--themes').length != 0 ||
    $('.bloc--formule').length != 0 ){
        sliders();
    }
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.bloc--jobs').length != 0) { accordeonJobs(); }
    accordeonNav();
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();

    // ACTIONS WINDOW
    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    $(window).on('resize', function(){
        resize();
    });

    filter();

    if( $('.form-habitation-input').length !=0 ){
        $('.label-habitation').each(function(){
            $(this).on('click', function(){
                $('.label-habitation').removeClass('checked');
                $(this).addClass('checked');
            });
        });
    }

    menuCustom();
    menuCustomMobile();
    landingTitle();
});

function resize(){
    $('.header--page, .flex--help').css( 'padding-top', $('#masthead').innerHeight()*2 );

    if($('body.admin-bar').length != 0){
        $('#masthead').css('top', $('#wpadminbar').innerHeight());
        // Si la fenêtre est plus petite que 600px et que la page est scrollée
        if($(window).width() < 586 && $(window).scrollTop() != 0 ){
            $('body.admin-bar #nav-bt').css('top', ($('#masthead').innerHeight()*0.5 - $('#nav-bt').innerHeight()*0.5) + 18 );
        }else{
            $('body.admin-bar #nav-bt').css('top', ($('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#nav-bt').innerHeight()*0.5) + 18 );
        }

        $(window).on('scroll', function(){
            // Si la fenêtre est plus petite que 600px et que la page est scrollée
            if($(window).width() < 586 && $(window).scrollTop() != 0 ){
                $('body.admin-bar #nav-bt').css('top', ($('#masthead').innerHeight()*0.5 - $('#nav-bt').innerHeight()*0.5) + 18 );
            }else{
                $('body.admin-bar #nav-bt').css('top', ($('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#nav-bt').innerHeight()*0.5) + 18 );
            }
        });
    }

    if( $(window).width() < 456 ){
        $('body.home .grille-annonce--l3, body.single-post .grille-annonce--l3').not('.slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            respondTo: 'slider',
            responsive: true,
            arrows: true,
            dots: false,
            infinite: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '18px',
            prevArrow: '<span class="slide-prev"><i class="fa-solid fa-chevron-left"></i></span>',
            nextArrow: '<span class="slide-next"><i class="fa-solid fa-chevron-right"></i></span>',
        });
    }
}

function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling'); 
    } else {
        $('body').removeClass('scrolling');
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });
}

function sliders(){
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: true,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-chevron-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-angle-right"></i></span>',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    });

    $('.intro--galerie').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        lazyLoad: 'ondemand',
    });

    $('.bloc--slider-cnt').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true
    });

    $('.bloc--sliderChiffres').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            }
          ]
    });

    $('.devis--themes').not('.slick-initialized').slick({
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-arrow-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-arrow-right"></i></span>',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                }
            }
          ]
    });

    $('.formules--mrh .bloc--formule').not('.slick-initialized').slick({
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: false,
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-arrow-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-arrow-right"></i></span>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                }
            },
          ]
    });

    $('.formules--auto .bloc--formule, .formules--moto .bloc--formule').not('.slick-initialized').slick({
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: false,
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-arrow-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-arrow-right"></i></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                }
            },
          ]
    });

    $('.formules--vsp .bloc--formule').not('.slick-initialized').slick({
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: false,
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-arrow-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-arrow-right"></i></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                }
            },
          ]
    });

    $('.bloc--shortcut .flex').not('.slick-initialized').slick({
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: false,
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-arrow-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-arrow-right"></i></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            },
          ]
    });
}

function onglets(){
    $('.tabs .tab').on('click', function(e){
        e.preventDefault();

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            $(this).parent('li').addClass('active');

            return false;
        }
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function accordeonJobs(){
    $('.bloc--jobs .job--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".job .job--main").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).nextAll(".job--content:visible").length != 0) {
            $(this).nextAll(".job--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".job .job--content").slideUp("normal");
            $(".job .job--main").removeClass('open');
            $(this).nextAll(".job--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function accordeonNav(){
    $('.nav--accordeon ul.menu').hide();

    if( $(window).width() < 1010 ){
        // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
        // et on remplace l'élément span qu'ils contiennent par un lien :
        $(".nav--accordeon .widget-title").on('click', function () {
            console.log('mobile test');
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(this).next('div').find('ul.menu:visible').length != 0) {
                $(this).next('div').find('ul.menu').slideUp("normal");
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $(".nav--accordeon ul.menu").slideUp("normal");
                $(this).next('div').find('ul.menu').slideDown("normal");
            }
            // On empêche le navigateur de suivre le lien :
            return false;
        });
    } else {      
        $(".nav--accordeon .widget-title").on('click', function () {
            console.log('bureau test');
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(".nav--accordeon ul.menu:visible").length != 0) {
                $(".nav--accordeon ul.menu").slideUp("normal");
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $(".nav--accordeon ul.menu").slideDown("normal");
            }
            return false;
        });
    }
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}

function filter(){
    $('.bloc--categories li').each(function(){
        var filter = $(this).find('.filter');
        filter.on('click', function(){
            $('.ann').hide().animate({opacity: 0}, 100);
            var filterData = $(this).attr('data-filter');
            $('.'+filterData).show().animate({opacity: 1}, 200);
        });
    });

    $('.bloc--categories li .all').on('click', function(){
        $('.ann').show().animate({opacity: 1}, 200);
    });
}

function menuCustom(){
    $('#nav-main .sub-menu-premier').each(function(){
        $(this).css('height', $(this).next('ul.sub-menu').innerHeight());
        var headText = $(this).find('.head--text-cnt').html();
        $(this).find('a').not('.head--link').remove();
        $(this).prepend(headText);

        $(window).on('resize', function(){
            $('#nav-main .sub-menu-premier').each(function(){
                $(this).css('height', $(this).next('ul.sub-menu').innerHeight());
            });
        })
    });

    $('#nav-main .sub-menu-deuxieme').each(function(){
        var menuText = $(this).find('.menucnt--text').html();
        $(this).find('a').not('.menucnt--link').remove();
        $(this).prepend(menuText);
    });
}

function menuCustomMobile(){
    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
        $('#btProfil').toggle();
        $('#nav-mobile li.menu-item-has-children').removeClass('active').children('ul.sub-menu').slideUp();
    });

    $('#nav-mobile > li.menu-item-has-children').each(function(){
        var parent = $(this);
        var brothers = $(this).siblings('.menu-item-has-children');
        var childs = $('#nav-mobile > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children');

        $(this).children("a").on('click', function(event){
            event.preventDefault();
            brothers.removeClass('active').children('ul.sub-menu').slideUp();
            childs.removeClass('active').children('ul.sub-menu').slideUp();

            parent.toggleClass('active').children('ul.sub-menu').slideToggle(function(){
                if ($(this).is(":visible")) {
                    $(this).css("display", "flex");
                }   
            });
        });
    });

    $('#nav-mobile > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children').each(function(){
        var parent = $(this);
        var brothers = $(this).siblings('.menu-item-has-children');

        $(this).children("a").on('click', function(event){
            event.preventDefault();
            brothers.removeClass('active').children('ul.sub-menu').slideUp();

            parent.toggleClass('active').children('ul.sub-menu').slideToggle(function(){
                if ($(this).is(":visible")) {
                    $(this).css("display", "flex");
                }   
            });
        });
    });
    
}

function landingTitle(){
    if( $('body.page-template-page-landing').length != 0 ){
        var titleH1 = $('.page--content h1').html();
        $('.header--page h1').html(titleH1);
        $('.page--content h1').remove();
    }
}